import { get, put,Delete,post } from '../axios'

// 商品分类-新增
export const goodsCategoryAdd = params => post(`/goods/web/goodsCategory/add`,params);
// 商品分类-列表
export const goodsCategoryList = params => get(`/goods/web/goodsCategory/all/list`,params);
// 商品分类-删除
export const goodsCategoryDel = params => Delete(`/goods/web/goodsCategory/delete/${params}`);
// 商品分类-是否显示
export const goodsCategoryState = params => put(`/goods/web/goodsCategory/state`,params);
// 商品分类-编辑
export const goodsCategoryUpdate = params => put(`/goods/web/goodsCategory/update`,params);

// 商品-新增
export const goodsAdd = params => post(`/goods/web/goods/add`,params);
// 商品-商品审核
export const goodsCheck = params => put(`/goods/web/goods/platform/check`,params);
// 商品-删除
export const goodsDel = params => Delete(`/goods/web/goods/delete/${params}`);
// 商品-分页列表
export const goodsPageList = params => get(`/goods/web/goods/platform/pageList`,params);

// 商品-上下架-批量上下架
export const goodsShelve = params => put(`/goods/web/goods/platform/shelve`,params);
// 商品-编辑
export const goodsUpdate = params => put(`/goods/web/goods/update`,params);
// 商品-查看
export const goodsView = params => get(`/goods/web/goods/view/${params}`);

// 平台-设置为首页品牌商品
export const goodsSet = params => put(`/goods/web/goods/platform/set`,params);


//商品品牌 -新增
export const goodBrandAdd = params => post(`/goods/web/goodBrand/add`,params);
//商品品牌 -删除
export const goodBrandDel = params => Delete(`/goods/web/goodBrand/delete/${params}`);
//商品品牌 -分页列表
export const goodBrandPageList = params => get(`/goods/web/goodBrand/vendor/pageList`,params);
//商品品牌 -是否显示
export const goodBrandState = params => put(`/goods/web/goodBrand/state`,params);
//商品品牌 -编辑 
export const goodBrandUpdate = params => put(`/goods/web/goodBrand/update`,params);
//商品品牌 -编辑
export const goodBrandListAll = params => get(`/goods/web/goodBrand/list`,params);

//商品分组 -新增
export const goodsClassifyAdd = params => post(`/goods/web/goodsClassify/add`,params);
//商品分组 -删除
export const goodsClassifyDel = params => Delete(`/goods/web/goodsClassify/delete/${params}`);
//商品分组 -分页列表
export const goodsClassifyList = params => get(`/goods/web/goodsClassify/all/list`,params);
//商品分组 -是否显示
export const goodsClassifyState = params => put(`/goods/web/goodsClassify/state`,params);
//商品分组 -编辑
export const goodsClassifyUpdate = params => put(`/goods/web/goodsClassify/update`,params);

// 门店商品-分页列表
export const goodsStorePageList = params => get(`/goods/web/goods/store/pageList`,params);
// 平台-门店商品列表(banner跳转选择)
export const platformStorePageList= params => get(`/goods/web/goods/platform/store/pageList`,params);

// 平台商品-商品池
export const goodsPlatformPool = params => get(`/goods/web/goods/platform/pool/pageList`,params);

// 平台商品-平台分组列表
export const goodsClassifyAllList = params => get(`/goods/web/goodsClassify/all/list`,params);

// 门店端-部门树
export const goodsDeptTree = params => get(`/user/web/store/deptTree`,params);

// 门店商品-新增
export const goodsStoreAdd = params => post(`/goods/web/goods/store/add`,params);

// 门店商品-新增
export const goodsStoreUp = params => put(`/goods/web/goods/store/update`,params);
// 门店商品-批量上下架-推荐商品
export const storeShelve = params => put(`/goods/web/goods/store/shelve`,params);

// 门店商品-移除-批量移除
export const storeDel = params => put(`/goods/web/goods/store/delete`,params);

// 门店商品-移除-批量移除
export const storeView = params => get(`/goods/web/goods/store/view/${params}`); 

// 门店分组-新增
export const goodsClassifyStoreAdd = params => post(`/goods/web/goodsClassify/store/add`,params);
//商品分组 -删除
export const goodsClassifyStoreDel = params => Delete(`/goods/web/goodsClassify/store/delete/${params}`);
//门店-分组列表
export const goodsClassifyStoreList = params => get(`/goods/web/goodsClassify/store/list`,params);
//门店分组-是否首页弹框显示
export const goodsClassifyStorePopup = params => put(`/goods/web/goodsClassify/store/popup`,params);
//门店分组-是否显示
export const goodsClassifyStoreState = params => put(`/goods/web/goodsClassify/store/state`,params);
//门店分组-编辑
export const goodsClassifyStoreUpdate = params => put(`/goods/web/goodsClassify/store/update`,params);
//门店分组-编辑
export const exportData = params => post(`/goods/web/goods/store/importGoodsClassify`,params);